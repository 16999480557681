import * as yup from 'yup';
import addDays from 'date-fns/addDays';
import differenceInHours from 'date-fns/differenceInHours';

import {
  TaskImplementationDate,
  TaskNanoID,
  TaskRevisionFields
} from '../../../../../tasksTypes';
import { RevisionTaskFormData } from '../../RevisionTaskForm.types';

import {
  REVISION_TASK_QUERY,
  RevisionTaskQueryResponse
} from '../../../../../queries/revisionTask.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useRevisionTask } from '../../../../../hooks/useRevisionTask';

import { dateFnsConvert } from '../../../../../../../utils/dateFnsConvert';

import { formsErrors } from '../../../../../../../locales/keys';

const updateImplementationDate = addDays(new Date(), 1).toISOString();

interface RevisionTaskFormOptions {
  taskNanoId: TaskNanoID;
  implementationDate?: TaskImplementationDate;
}

const revisionTaskValidationSchema = yup.object({
  [TaskRevisionFields.IMPLEMENTATION_DATE]: yup
    .string()
    .nullable(true)
    .required(formsErrors.required)
});

function useRevisionTaskForm({
  taskNanoId,
  implementationDate
}: RevisionTaskFormOptions) {
  const isChangeImplementationData =
    !implementationDate ||
    differenceInHours(
      new Date(dateFnsConvert.toDateTimeLocal(implementationDate)),
      new Date()
    ) < 12;

  const defaultImplementationDate = implementationDate
    ? dateFnsConvert.toDateTimeLocal(implementationDate)
    : updateImplementationDate;

  const { handleSubmitReactHookForm, register, errors, control, resetForm } =
    useReactHookForm<RevisionTaskFormData>({
      defaultValues: {
        feedback: '',
        implementationDate: isChangeImplementationData
          ? updateImplementationDate
          : defaultImplementationDate
      },
      isModalForm: true,
      schema: revisionTaskValidationSchema
    });

  const {
    revisionTask,
    revisionTaskLoading,
    revisionTaskErrorMessage,
    revisionTaskReset
  } = useRevisionTask<RevisionTaskQueryResponse>({
    query: REVISION_TASK_QUERY
  });

  return {
    control,
    revisionTask,
    revisionTaskLoading,
    revisionTaskErrorMessage,
    revisionTaskReset,
    resetForm,
    handleRevisionTask: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data: RevisionTaskFormData) =>
        revisionTask({
          uuid: taskNanoId,
          ...data,
          implementationDate: dateFnsConvert.toDateTimeWithTimezone(
            data.implementationDate
          )
        })
    }),
    registerImplementationDate: register(
      TaskRevisionFields.IMPLEMENTATION_DATE
    ),
    registerFeedback: register(TaskRevisionFields.FEEDBACK),
    validationErrors: {
      implementationDateValidationError: errors?.implementationDate?.message
    }
  };
}

export default useRevisionTaskForm;
