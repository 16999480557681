import React, { Fragment } from 'react';

import {
  TaskNanoID,
  TaskStatuses
} from '../../../../../../../../../../tasks/tasksTypes';
import { DashboardActiveTaskMessageHeaderTask } from './DashboardActiveTaskMessageHeader.types';

import { TrackTimeInTaskModalButton } from '../../../../../../../../../../tasks/components/modalButtons/TrackTimeInTaskModalButton';
import { SubmitResultsToTaskModalButton } from '../../../../../../../../../../tasks/components/modalButtons/SubmitResultsToTaskModalButton';
import { DashboardActiveTaskMessageHeaderSmartContractPayments } from '../DashboardActiveTaskMessageHeaderSmartContractPayments';
import { DashboardActiveTaskMessageHeaderTaskCheckInWrapper } from './DashboardActiveTaskMessageHeaderTaskCheckInWrapper';

import { NextPureLinkHelper } from '../../../../../../../../../../../helpers/links/NextPureLinkHelper';
import { Status } from '../../../../../../../../../../../helpers/Status';
import { CheckPermissions } from '../../../../../../../../../../../helpers/CheckPermissions';

import { TaskPath } from '../../../../../../../../../../tasks/TaskPath';
import { ProjectPath } from '../../../../../../../../../../projects/ProjectPath';

import { TasksPermissions } from '../../../../../../../../../../tasks/tasksConstants';
import { SmartContractSharesPermissions } from '../../../../../../../../../../smartContractShares/smartContractSharesConstants';
import { IconsEnum } from '../../../../../../../../../../../assets/icons/types';
import { tasksKeys } from '../../../../../../../../../../../locales/keys';

interface DashboardActiveTaskMessageHeaderProps {
  task: DashboardActiveTaskMessageHeaderTask;
  isTaskMember: boolean;
}

function DashboardActiveTaskMessageHeader({
  task,
  isTaskMember
}: DashboardActiveTaskMessageHeaderProps) {
  if (!task) {
    return null;
  }

  const withSubmitResultsButton =
    isTaskMember &&
    task.status !== TaskStatuses.DONE &&
    task.status !== TaskStatuses.SETTING_TASK &&
    task.status !== TaskStatuses.CANCELED;

  const withTrackTimeButton =
    isTaskMember &&
    task.status !== TaskStatuses.DONE &&
    task.status !== TaskStatuses.CANCELED;

  return (
    <div className="px-2 pb-2 pt-1 flex items-center border-b dark:border-gray-800">
      <div className="flex-1">
        <div className="flex space-x-1.5 pr-10">
          {task.project && (
            <Fragment>
              <NextPureLinkHelper
                text={task.project.name}
                href={ProjectPath.show(task.project.nanoId)}
                className="text-sm mb-1 hover:underline min-w-5 line-clamp-1 break-all"
              />
              <span>/</span>
            </Fragment>
          )}

          <NextPureLinkHelper
            text={task.name}
            href={TaskPath.show(task.nanoId)}
            className="pr-10 text-sm mb-1 hover:underline font-medium min-w-fit text-black dark:text-white line-clamp-1 break-all"
          />
        </div>

        <div className="flex gap-2">
          <Status status={task.status} />

          <CheckPermissions
            action={
              TasksPermissions.READ_TASK_ACTIVE_TASK_SMART_CONTRACT_PAYMENTS_BLOCK
            }
          >
            <DashboardActiveTaskMessageHeaderSmartContractPayments
              taskNanoId={task.nanoId}
            />
          </CheckPermissions>
        </div>
      </div>
      <div className="flex gap-x-px">
        <CheckPermissions
          action={
            SmartContractSharesPermissions.READ_CHECK_IN_SMART_CONTRACT_SHARE_BUTTON
          }
        >
          <DashboardActiveTaskMessageHeaderTaskCheckInWrapper
            taskNanoId={task.nanoId}
          />
        </CheckPermissions>

        {withTrackTimeButton && (
          <CheckPermissions
            action={TasksPermissions.READ_TASK_TRACK_TIME_BUTTON}
          >
            <TrackTimeInTaskModalButton
              className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              taskUuid={task.uuid}
              taskNanoId={task.nanoId as TaskNanoID}
              icon={IconsEnum.CLOCK_OUTLINE}
              tooltipI18nText={tasksKeys.trackTime}
            />
          </CheckPermissions>
        )}

        {withSubmitResultsButton && (
          <CheckPermissions
            action={TasksPermissions.READ_TASK_SUBMIT_RESULTS_ACTION_BUTTON}
          >
            {task.status !== 'canceled' && task.status !== 'performed' && (
              <SubmitResultsToTaskModalButton
                taskNanoId={task.nanoId}
                projectNanoId={task.project?.nanoId}
                teamNanoId={task.project?.team?.nanoId}
                icon={IconsEnum.ROCKET_OUTLINE}
                className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                iconClassName="h-6 w-6"
                tooltipI18nText={tasksKeys.submitResults}
              />
            )}
          </CheckPermissions>
        )}
      </div>
    </div>
  );
}

export default DashboardActiveTaskMessageHeader;
