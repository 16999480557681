import React, { useMemo } from 'react';
import find from 'lodash/find';
import some from 'lodash/some';

import { TaskNanoID } from '../../../../../../../../../../../tasks/tasksTypes';
import { IconsEnum } from '../../../../../../../../../../../../assets/icons/types';
import { SmartContractStatuses } from '../../../../../../../../../../../smartContracts/smartContractsTypes';

import {
  FETCH_TASK_SMART_CONTRACTS_QUERY,
  FetchTaskSmartContractsQueryResponse
} from '../../../../../../../../../../../smartContracts/queries/fetchTaskSmartContracts.query';

import { useFinPaginatedSmartContracts } from '../../../../../../../../../../../smartContracts/hooks/useFinPaginatedSmartContracts';
import { useCurrentUser } from '../../../../../../../../../../../../auth/hooks/useAuth';

import { TaskCheckInWithOptionsButton } from '../../../../../../../../../../../tasks/components/modalButtons/TaskCheckInWithOptionsButton';

import { SmartContractSharesPermissions } from '../../../../../../../../../../../smartContractShares/smartContractSharesConstants';

import { SmartContractCache } from '../../../../../../../../../../../smartContracts/SmartContractCache';
import { TaskMemberInviteCache } from '../../../../../../../../../../../taskMemberInvites/TaskMemberInviteCache';

import { SmartContractShareStatuses } from '../../../../../../../../../../../smartContractShares/smartContractSharesTypes';

interface DashboardActiveTaskMessageHeaderTaskCheckInWrapperProps {
  taskNanoId: TaskNanoID;
}

function DashboardActiveTaskMessageHeaderTaskCheckInWrapper({
  taskNanoId
}: DashboardActiveTaskMessageHeaderTaskCheckInWrapperProps) {
  const currentUser = useCurrentUser();

  const { smartContracts } =
    useFinPaginatedSmartContracts<FetchTaskSmartContractsQueryResponse>({
      query: FETCH_TASK_SMART_CONTRACTS_QUERY,
      cacheKey: SmartContractCache.taskSmartContractsCacheKey(taskNanoId),
      initialFilters: { taskNanoId: { eq: taskNanoId } },
      options: {
        withoutPrefetch: true,
        enabled: !!taskNanoId,
        enabledPlaceholder: !!taskNanoId
      }
    });

  const draftSmartContract = useMemo(
    () =>
      find(
        smartContracts,
        (smartContract) => smartContract?.status === SmartContractStatuses.DRAFT
      ),
    [smartContracts]
  );

  const draftSmartContractWithInvited = useMemo(
    () =>
      some(
        draftSmartContract?.smartContractShares,
        (smartContractShare) =>
          smartContractShare.status === SmartContractShareStatuses.INVITED &&
          smartContractShare.generalLedger?.company?.owner?.id ===
            currentUser.id
      )
        ? draftSmartContract
        : undefined,
    [currentUser.id, draftSmartContract]
  );

  const smartContractShareId = useMemo(
    () =>
      draftSmartContractWithInvited?.smartContractShares?.find(
        (smartContractShare) =>
          draftSmartContractWithInvited?.status ===
            SmartContractStatuses.DRAFT &&
          smartContractShare?.generalLedger?.company?.owner?.id ===
            currentUser.id
      )?.id,
    [draftSmartContractWithInvited, currentUser.id]
  );

  return (
    <TaskCheckInWithOptionsButton
      firstButtonAction={
        SmartContractSharesPermissions.READ_CHECK_IN_SMART_CONTRACT_SHARE_BUTTON
      }
      secondButtonAction={
        SmartContractSharesPermissions.READ_CHECK_IN_WITHOUT_FOLLOWERS_SMART_CONTRACT_SHARE_BUTTON
      }
      taskNanoId={taskNanoId}
      checkInSmartContractShareId={smartContractShareId}
      smartContractId={draftSmartContract?.id}
      cacheKeys={[
        TaskMemberInviteCache.userTaskMemberInvitesCacheKey(currentUser.nanoId)
      ]}
      className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      icon={IconsEnum.CLIPBOARD_DOCUMENT_CHECK_OUTLINE}
      iconClassName="h-6 w-6"
    />
  );
}

export default DashboardActiveTaskMessageHeaderTaskCheckInWrapper;
