import React, { useCallback } from 'react';

import { DashboardTasksResultsListItemFileItem } from './DashboardTasksResultsListItemFile.types';

import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';
import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../helpers/ImageHelper';
import { OnLightboxOpenAction } from '../../../../../../../helpers/LightboxWrapper';
import { FileAttachmentHelper } from '../../../../../../../helpers/FileAttachmentHelper';

import { Files } from '../../../../../../../utils/Files';
import { getFileAttachmentName } from '../../../../../../fileAttachments/utils/getFileAttachmentName';

interface DashboardTasksResultsListItemFileProps {
  fileAttachment: DashboardTasksResultsListItemFileItem;
  onLightboxOpen: OnLightboxOpenAction;
}

function DashboardTasksResultsListItemFile({
  fileAttachment,
  onLightboxOpen
}: DashboardTasksResultsListItemFileProps) {
  const handleOpenLightbox = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      e.preventDefault();
      onLightboxOpen({ uuid: fileAttachment.uuid, image: fileAttachment });
    },
    [fileAttachment, onLightboxOpen]
  );

  return (
    <LinkHelper
      key={fileAttachment.uuid}
      className="block relative h-40 w-40 shrink-0 rounded overflow-hidden border border-white dark:border-gray-900 bg-gray-300 dark:bg-gray-800 z-5"
      href={fileAttachment.file}
      target="_blank"
      onClick={
        Files.isImage(fileAttachment.file) ? handleOpenLightbox : undefined
      }
    >
      {Files.isImage(fileAttachment.file) ? (
        <ImageHelper
          className="h-full w-full object-cover"
          src={fileAttachment.file}
          alt={getFileAttachmentName(fileAttachment)}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
          tooltipAlt
        />
      ) : (
        <FileAttachmentHelper
          src={fileAttachment.file}
          name={getFileAttachmentName(fileAttachment)}
          size={fileAttachment.size}
        />
      )}
    </LinkHelper>
  );
}

export default DashboardTasksResultsListItemFile;
